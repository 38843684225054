<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">
        <a-row>
          <a-col :span="18">
            <a-form-model-item>
              <a-input placeholder="设计编号" v-model="form.designCode" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="欠款单位" v-model="form.buyerUnitName" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="发票号码" v-model="form.invoiceCode" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="业务内容" v-model="form.business" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="生产负责人" v-model="form.liquidatorName" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="清收情况" v-model="form.isLiquidateComplete" style="width: 150px">
                <a-select-option v-for="item in [
                                                    {
                                                      name: '完成清收',
                                                      value: 1,
                                                    },
                                                    {
                                                      name: '未完成清收',
                                                      value: 0,
                                                    },
                                                  ]" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="填报状态" v-model="form.status" style="width: 150px">
                <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">{{ item.name
                                  }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <div class="right" style="margin-top: 4px">
              <a-space>
                <Uploader v-if="$getPermission($route.path + '/import-payment')"
                  @setLoading="(payload) => (importPaymentLoading = payload)" @refresh="getList" type="importPayment">
                  <a-button :loading="importPaymentLoading">导入回款记录</a-button>
                </Uploader>

                <Uploader v-if="$getPermission($route.path + '/import')"
                  @setLoading="(payload) => (importLoading = payload)" @refresh="getList" type="importReceive">
                  <a-button :loading="importLoading">导入应收账款</a-button>
                </Uploader>

                <a-button v-if="$getPermission($route.path + '/export')" @click="download"
                  type="primary">导出应收账款</a-button>
              </a-space>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table bordered :data-source="list" :loading="loading" @change="onChange" :pagination="{
                      total,
                      current,
                      pageSize,
                      showTotal: (total) => `共 ${total} 条记录`,
                    }" :scroll="scroll">
        <a-table-column title="发票号码">
          <template slot-scope="text">
            {{ text.invoice ? text.invoice.invoiceCode : "" }}
          </template>
        </a-table-column>
        <a-table-column title="欠款单位">
          <template slot-scope="text">
            {{ text.invoice ? text.invoice.buyerUnitName : "" }}
          </template>
        </a-table-column>
        <a-table-column title="设计编号" data-index="designCode" />
        <a-table-column title="业务内容">
          <template slot-scope="text">
            {{ text.invoice ? text.invoice.business : "" }}
          </template>
        </a-table-column>
        <a-table-column title="开票日期" align="center">
          <template slot-scope="text">
            {{ text.invoice ? text.invoice.invoiceDate : "" }}
          </template>
        </a-table-column>
        <a-table-column title="账期(天)" align="center">
          <template slot-scope="text">{{ text.accountPeriod }}</template>
        </a-table-column>
        <a-table-column title="发票金额(元)" align="right">
          <template slot-scope="text">
            <span v-if="
                                        text.invoice &&
                                        !isNaN(parseFloat(text.invoice.totalPriceLowercase))
                                      " class="money-color">
              {{
                            parseFloat(text.invoice.totalPriceLowercase).toLocaleString()
                            }}
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>
        <a-table-column title="应收余额(元)" align="right">
          <template slot-scope="text">
            <span v-if="typeof text.sumRemaining === 'number'" class="money-color">
              {{ text.sumRemaining.toLocaleString() }}
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>
        <a-table-column title="本年回款额(元)" align="right">
          <template slot-scope="text">
            <span v-if="typeof text.sumReceivedThisyear === 'number'" class="money-color">
              {{ text.sumReceivedThisyear.toLocaleString() }}
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>
        <a-table-column title="当日余额(元)" align="right">
          <template slot-scope="text">
            <span v-if="typeof text.sumRemainingThisday === 'number'" class="money-color">
              {{ text.sumRemainingThisday.toLocaleString() }}
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>
        <a-table-column title="合同资产(元)" align="right">
          <template slot-scope="text">
            <span v-if="typeof text.contractAssets === 'number'" class="money-color">
              {{ text.contractAssets.toLocaleString() }}
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>

        <a-table-column title="任务预测金额(元)" align="right" width="110px">
          <template slot-scope="text">
            <span v-if="typeof text.taskSum === 'number'" class="money-color">
              {{ text.taskSum.toLocaleString() }}
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>

        <a-table-column title="任务预测时间" align="center" data-index="taskFillDate">
        </a-table-column>

        <a-table-column title="生产负责人" align="center">
          <template slot-scope="text">
            {{ text.invoice ? text.invoice.liquidatorName : "" }}
          </template>
        </a-table-column>
        <a-table-column title="区域负责人" align="center">
          <template slot-scope="text">
            {{ text.invoice ? text.invoice.areaMasterName : "" }}
          </template>
        </a-table-column>
        <a-table-column title="填报状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="account_collect_status" :dictValue="text.status" iconType="text" />
          </template>
        </a-table-column>

        <a-table-column align="right" width="60px" fixed="right">
          <div style="padding-right: 7px" slot="title">操作</div>
          <template slot-scope="text">
            <a href="#" v-if="$getPermission($route.path + '/detail')" @click.prevent="
                                        $router.push($route.path + '/detail?id=' + text.id)
                                      ">详情</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";

import { fetchList, downloadFile } from "@/api/finance/accounts-receivable";
import { mapGetters } from "vuex";
import Uploader from "./components/uploader.vue";
import { saveAs } from "file-saver";
export default {
  name: "accounts-receivable",
  mixins: [screen, watermark],

  components: {
    Uploader,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      importLoading: false,
      importPaymentLoading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("account_collect_status");
    },
  },

  activated() {
    const { query } = this.$route;
    const { invoiceCode } = query || {};
    if (invoiceCode) {
      this.form = {
        invoiceCode,
      };
    }
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    // 导出
    download() {
      downloadFile(this.form).then((blob) => {
        saveAs(blob, "应收账款.xlsx");
      });
    },
  },
};
</script>