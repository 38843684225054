import request from '../request'
import download from '../download'

export function fetchList(params) {
    return request({
        url: `/finance-service/account/receivable/list`,
        params,
    })
}

// 导入应收账款
export function importFile(data) {
    return request({
        url: '/finance-service/account/receivable/import',
        method: 'post',
        data,
    })
}

// 导出应收账款
export function downloadFile() {
    return download({
        url: '/finance-service/account/receivable/export',
        method: 'post',
    })
}

export function fetchDetail(params) {
    return request({
        url: '/finance-service/account/receivable/query/' + params.id,
    })
}

export function edit(data) {
    return request({
        url: '/finance-service/account/receivable/update',
        method: 'post',
        data,
    })
}

// 回款
export function fetchPaymentList(params) {
    return request({
        url: '/finance-service/account/payment/list',
        params
    })
}


export function addPayment(data) {
    return request({
        url: '/finance-service/account/payment/add',
        method: 'post',
        data
    })
}

export function deletePayment(data) {
    return request({
        url: '/finance-service/account/payment/del/' + data.id,
        method: 'post',
    })
}

// 导入回款
export function importPayment(data) {
    return request({
        url: '/finance-service/account/payment/import',
        method: 'post',
        data
    })
}

// 填报计划
export function addPlan(data) {
    return request({
        url: '/finance-service/account/collect/fill',
        method: 'post',
        data,
    })
}

// 给清收计划添加动态
export function fill(data) {
    return request({
        url: `/finance-service/account/collect/track/fill`,
        method: 'post',
        data,
    })
}
// 获取协作者列表
export function fetchHelperList({ id }) {
    return request({
        url: `/finance-service/account/collect/getAssistUserList?pid=${id}`,
    })
}