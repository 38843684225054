<template>
  <span>
    <input
      ref="costUploader"
      id="cost-uploader"
      type="file"
      @change="onChange"
    />

    <span @click="onClick">
      <slot />
    </span>
  </span>
</template>

<script>
import { importFile, importPayment } from "@/api/finance/accounts-receivable";
export default {
  props: {
    type: {
      type: String,
    },
  },

  methods: {
    onClick() {
      this.$refs.costUploader.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.$emit("setLoading", true);
      const hide = this.$message.loading("上传中...", 0);

      if (this.type === "importReceive") {
        importFile(data)
          .then((res) => {
            console.log("upload res", res);
            this.$emit("refresh");
          })
          .finally(() => {
            this.$emit("setLoading", false);
            hide();
            e.target.value = null;
          });
      }
      if (this.type === "importPayment") {
        importPayment(data)
          .then((res) => {
            console.log("upload res", res);
            this.$emit("refresh");
          })
          .finally(() => {
            this.$emit("setLoading", false);
            hide();
            e.target.value = null;
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
#cost-uploader {
  display: none;
}
</style>